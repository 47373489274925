var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "crumbs" },
      [
        _c(
          "el-breadcrumb",
          { attrs: { separator: "/" } },
          [
            _c("el-breadcrumb-item", [
              _c("i", { staticClass: "el-icon-pie-chart" }),
              _vm._v(" schart图表\n            ")
            ])
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "schart-box" },
        [
          _c("div", { staticClass: "content-title" }, [_vm._v("柱状图")]),
          _c("schart", {
            staticClass: "schart",
            attrs: { canvasId: "bar", options: _vm.options1 }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "schart-box" },
        [
          _c("div", { staticClass: "content-title" }, [_vm._v("折线图")]),
          _c("schart", {
            staticClass: "schart",
            attrs: { canvasId: "line", options: _vm.options2 }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "schart-box" },
        [
          _c("div", { staticClass: "content-title" }, [_vm._v("饼状图")]),
          _c("schart", {
            staticClass: "schart",
            attrs: { canvasId: "pie", options: _vm.options3 }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "schart-box" },
        [
          _c("div", { staticClass: "content-title" }, [_vm._v("环形图")]),
          _c("schart", {
            staticClass: "schart",
            attrs: { canvasId: "ring", options: _vm.options4 }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plugins-tips" }, [
      _vm._v(
        "\n            vue-schart：vue.js封装sChart.js的图表组件。\n            访问地址：\n            "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/lin-xin/vue-schart",
            target: "_blank"
          }
        },
        [_vm._v("vue-schart")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }